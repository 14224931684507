import PropTypes from 'prop-types'
import cns from 'classnames'

import {
  discount,
  periods,
  periodsBF,
} from '@components/pricingSwitchers/period/periodData'
import useFormatMessage from '@utils/useFormatMessage'
import useBlackFridayTime from '@utils/useBlackFridayTime'

import IconPricingArrow from '@icons/pricingArrow.svg'

import styles from './period.module.scss'
import { useEffect, useState } from 'react'

function Period({ selectedPeriod, setSelectedPeriod }) {
  const [rightPeriods, setRightPeriods] = useState([])
  const t = useFormatMessage()
  const { isBlackFridayTime } = useBlackFridayTime()

  const handleClick = period => () => {
    setSelectedPeriod(period)
  }

  useEffect(() => {
    setRightPeriods(isBlackFridayTime ? periodsBF : periods)
  }, [isBlackFridayTime])

  return (
    <div className={styles.root}>
      <div className={styles.actions}>
        {rightPeriods?.map((period, i) => (
          <button
            className={cns(
              styles.button,
              period !== selectedPeriod && 'button--ghost',
              period === selectedPeriod && 'button--secondary',
              isBlackFridayTime && styles.button_bf,
              'button button--small'
            )}
            onClick={handleClick(period)}
            key={i}
          >
            {t(`pricing.periods.${period}`)}
          </button>
        ))}
      </div>

      {!isBlackFridayTime && (
        <div className={styles.discount}>
          <div className={styles.discount__text}>
            {t(`pricing.periods.discount`)}

            {` ${discount}%`}
          </div>

          <IconPricingArrow className={styles.discount__arrow} />
        </div>
      )}
    </div>
  )
}

Period.propTypes = {
  selectedPeriod: PropTypes.string,
  setSelectedPeriod: PropTypes.func,
}

export default Period
